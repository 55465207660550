<template>
    <div class="gst-checkout-notes-container pl-6 pr-6">
        <div class="gst-checkout-notes-container__header d-flex mt-6 mb-6">
            <BaseIcon class="gst-checkout-notes-container__icon mr-2" symbol-id="icons--info-outlined" />
            <PrismicRichText :field="header" class="gst-checkout-notes-container__title" />
        </div>
        <PrismicRichText :field="content" class="gst-checkout-notes-container__content" />
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'CheckoutNotes',
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckout._components.containers.checkoutNotes'
        },
        components: {
            BaseIcon
        },
        props: {
            header: {
                type: Array,
                default() { return [ ]; }
            },
            content: {
                type: Array,
                required: true
            }
        }
    };
</script>

<style lang="scss" >
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-checkout-notes-container {
        display: flex;
        background: theme-color( 'quinary' );
        flex-direction: column;

        .gst-checkout-notes-container__header {
            align-items: center;

            .gst-checkout-notes-container__title {
                h5 {
                    font-size: font-size( 'xxl' ) !important;
                }
            }
        }

        .gst-checkout-notes-container__icon {
            .gst-svg-icon {
                fill: theme-color( 'primary' );
            }
        }

        .gst-checkout-notes-container__content {
            p ul li {
                font-size: font-size( 'l' ) !important;
            }
        }

        @include mobile-only {
            .gst-checkout-notes-container__title {
                h5 {
                    font-size: font-size( 'l' ) !important;
                }
            }

            .gst-checkout-notes-container__content {
                p {
                    font-size: font-size( 's' ) !important;
                }
            }
        }
    }
</style>