import shippingConstants from '@core/utils/constants/shipping';

export function sort( items = [ ] ) {
    const getSortOrder = ( a ) => {
        switch ( a.serviceLevel ) {
        case shippingConstants.OPTIONS.SERVICE_LEVEL.ETICKET:
            return shippingConstants.OPTIONS.SERVICE_LEVEL_SORT.ETICKET;
        case shippingConstants.OPTIONS.SERVICE_LEVEL.MOBILE:
            return shippingConstants.OPTIONS.SERVICE_LEVEL_SORT.MOBILE;
        case shippingConstants.OPTIONS.SERVICE_LEVEL.MOBILETICKET:
            return shippingConstants.OPTIONS.SERVICE_LEVEL_SORT.MOBILETICKET;
        case shippingConstants.OPTIONS.SERVICE_LEVEL.DIGITAL:
            return shippingConstants.OPTIONS.SERVICE_LEVEL_SORT.DIGITAL;
        case shippingConstants.OPTIONS.SERVICE_LEVEL.TICKETFAST:
            return shippingConstants.OPTIONS.SERVICE_LEVEL_SORT.TICKETFAST;
        case shippingConstants.OPTIONS.SERVICE_LEVEL.ELECTRONIC:
            return shippingConstants.OPTIONS.SERVICE_LEVEL_SORT.ELECTRONIC;
        default:
            return shippingConstants.OPTIONS.SERVICE_LEVEL_SORT.OTHER;
        }
    };

    const arr = [ ...items ];

    arr.sort( ( a, b ) =>  {
        if ( getSortOrder ( a ) < getSortOrder ( b ) ) return -1;
        if ( getSortOrder ( a ) > getSortOrder ( b ) ) return 1;
        return 0;
    } );

    return arr;
}

export function isElectronicTicketItem( item ) {
    return item.serviceLevel === shippingConstants.OPTIONS.SERVICE_LEVEL.ETICKET
    || item.serviceLevel === shippingConstants.OPTIONS.SERVICE_LEVEL.MOBILE
    || item.serviceLevel === shippingConstants.OPTIONS.SERVICE_LEVEL.MOBILETICKET
    || item.serviceLevel === shippingConstants.OPTIONS.SERVICE_LEVEL.DIGITAL
    || item.serviceLevel === shippingConstants.OPTIONS.SERVICE_LEVEL.TICKETFAST
    || item.serviceLevel === shippingConstants.OPTIONS.SERVICE_LEVEL.ELECTRONIC;
}

export function findOneElectronicTicketItem( items = [ ] ) {
    return items.find( item => isElectronicTicketItem( item ) );
}

export function findOneNonElectronicTicketItem( items = [ ] ) {
    return items.find( item => !isElectronicTicketItem( item ) );
}

export function findOneItemWithFees( items = [ ] ) {
    return items.find( item => item.totals.fee );
}

export function isMobileTicket( item ) {
    return item.serviceLevel === shippingConstants.OPTIONS.SERVICE_LEVEL.MOBILE;
}