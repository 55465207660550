import { formatDefault } from '@core/utils/currencyUtils';

/**
 * Currency value for 'en-US' locale plus the currency value
 * Example:
 * - 1000 Canadian Dollars: $100 CAD
 * - 1000 US Dollars: $100 USD
 * - 1000 Euro: E100 EUR
 * @param {Number} value
 * @param {String} currency
 * @param {Object} options
 */
export default function currencyFullFilter( value, currency ) {
    return formatDefault( value, currency );
}
