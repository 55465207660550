import i18next from 'i18next';
import dateConstants from '@core/utils/constants/date';
import dateFilter from '@tenant/app/filters/date';
import { getPrice } from '@tenant/app/utils/hotelReservationCancellationPolicies';
import currencyFilter from '@tenant/app/filters/currency';
import cancellationPolicy from './cancellationPolicy';

export default function ( { current, futures }, currency, basePrice, accommodationNights ) {
    const currentPolicy = current || futures[0];
    let text = `${cancellationPolicy( currentPolicy, currency, basePrice, accommodationNights )}.`;

    if ( currentPolicy && !futures ) {
        const time =  dateFilter( currentPolicy.fromAt, dateConstants.FORMAT.TIME );
        const date =  dateFilter( currentPolicy.fromAt, dateConstants.FORMAT.UI_DATE );
        const amount = currencyFilter( getPrice( currentPolicy, basePrice, accommodationNights ), currency );

        text += ` ${i18next.t( '_common:terms.freeCancellationCharge', { amount, date, time } )}.`;
    }

    if ( futures ) {
        for ( let index = 0; index < futures.length; index++ ) {
            const element = futures[index];
            const time =  dateFilter( element.fromAt, dateConstants.FORMAT.TIME );
            const date =  dateFilter( element.fromAt, dateConstants.FORMAT.UI_DATE );
            const amount = currencyFilter( getPrice( element, basePrice, accommodationNights ), currency );

            text += ` ${i18next.t( '_common:terms.freeCancellationCharge', { amount, date, time } )}.`;
        }
    }

    return text;
}