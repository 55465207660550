import feeConstants from '@core/utils/constants/fee';
import roomRateFeeConstants from '@core/utils/constants/roomRateFee';
import productsConstants from '@core/utils/constants/products';
import { cleanAndTranslate as feeUtilsCleanAndTranslate } from '@core/utils/feeUtils';
import { groupByLabelAndPrice as feeUtilsGroupByLabelAndPrice } from '@core/utils/feeUtils';
import { sortCompare } from '@core/utils/sortCompareUtils';

export default {
    computed: {
        ticket( ) {
            const items = this.order.items || this.cartCollections;

            return items.find( item => item.productTypeId === productsConstants.TYPES.TICKET );
        },
        hotel() {
            const items = this.order.items || this.cartCollections;

            return items.find( item => item.productTypeId === productsConstants.TYPES.HOTEL_RESERVATION );
        },
        ticketOffers() {
            return this.ticket && this.ticket.offers ? this.ticket.offers : [];
        },
        ticketOffersWithFees( ) {
            if ( !this.ticketOffers.length ) {
                return [];
            }

            return this.ticketOffers
                .map( ( { name, quantity, charges } ) => {
                    return {
                        name,
                        quantity,
                        fees: feeUtilsCleanAndTranslate( charges, name ).filter( fee => fee.type !== feeConstants.TYPES.BASE )
                    };
                } );
        },
        orderWithFees( ) {
            if ( !this.order || !this.order.charges ) {
                return [];
            }

            const feesFiltered = this.order.charges
                .map( fee => {
                    return {
                        ...fee,
                        prependLabel: true
                    };
                } )
                .filter( fee => ( !fee.included || fee.included === roomRateFeeConstants.TYPES_INCLUDED.PAY_NOW ) && fee.type !== feeConstants.TYPES.DELIVERY );

            return feeUtilsCleanAndTranslate( feesFiltered );
        },
        shippingOption( ) {
            const { optionId, options } = this.shippingModel;

            if ( optionId ) {
                return options.find( item => item.id === optionId );
            }

            return null;
        },
        feesTickets( ) {
            if ( !this.ticketOffersWithFees.length ) {
                return [];
            }

            return this.ticketOffersWithFees
                .reduce( ( acc, item ) => {
                    acc = acc.concat( item.fees.map( fee => {
                        return {
                            name: fee.label,
                            quantity: fee.quantity,
                            price: ( fee.price + fee.tax ),
                            amount: ( fee.price + fee.tax ) * fee.quantity,
                            currency: this.currency,
                            isOrderFee: false,
                            order: fee.order
                        };
                    } ) );

                    return acc;
                }, [] )
                .sort( ( a, b ) => sortCompare( a.order, b.order ) );

        },
        feesHotels( ) {
            const fees = this.hotel ? this.hotel.charges : [];

            if ( !fees.length ) {
                return [];
            }

            return fees
                .filter( item => item.included === roomRateFeeConstants.TYPES_INCLUDED.PAY_NOW )
                .map( item => {
                    return {
                        name: item.name,
                        quantity: item.quantity,
                        price: item.price,
                        amount: item.price * item.quantity,
                        currency: this.currency,
                        isOrderFee: false
                    };
                } );
        },
        feesHotelsIncludedAmount() {
            const fees = this.hotel ? this.hotel.charges : [];

            if ( !fees.length ) {
                return 0;
            }

            return fees
                .filter( item => item.included === roomRateFeeConstants.TYPES_INCLUDED.INCLUDED )
                .reduce( ( acc, item ) => {
                    return acc + item.price;
                }, 0 );
        },
        feesHotelsNotIncludedAmount() {
            const fees = this.hotel ? this.hotel.charges : [];

            if ( !fees.length ) {
                return 0;
            }

            return fees
                .filter( item => item.included === roomRateFeeConstants.TYPES_INCLUDED.PAY_LATER )
                .reduce( ( acc, item ) => {
                    return acc + item.price;
                }, 0 );
        },
        feesHotelsNotIncludedTooltipContent() {
            const amount = this.$options.filters.currencyFilter( this.feesHotelsNotIncludedAmount, this.currency );
            return this.$t( '_common:terms.feesNotIncludedContent', { amount: amount } );
        },
        feesOrder( ) {
            if ( !this.orderWithFees ) {
                return [];
            }

            return this.orderWithFees
                .map( item => {
                    return {
                        name: item.label,
                        quantity: 1,
                        price: ( item.price + item.tax ),
                        amount: ( item.price + item.tax ) * 1,
                        currency: this.currency,
                        isOrderFee: true
                    };
                } );
        },
        fees: function () {
            const fees = [
                ...this.feesTickets,
                ...this.feesHotels,
                ...this.feesOrder
            ];

            return feeUtilsGroupByLabelAndPrice( fees );
        },
        hasFees() {
            return this.fees.length || this.feesHotelsNotIncludedAmount;
        }
    }
};